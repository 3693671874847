<template>
  <div class="EnquireyBanner">
    <div class="homepage">
        <b-row>
            <b-col>
                <h2 class="Whitetitle">Private and discrete booking available</h2>
                <p class="WhiteStandard-text aboutTrack-width">Get in touch today to book our whole studio to allow total privacy during your sessions.</p>
            </b-col>
        </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Enquirey Banner',
  components: {

  },
  data: function() {
    return {
      acf: [],
    };
  },
  methods: {
    isMobile() {
        if( screen.width <= 500 ) {
            return true;
        }
        else {
            return false;
        }
    },
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + 'simpark' + "ocs-studios/wp-json/wp/v2/pages/6"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data
        )); //API data wanted pulled out
  },
}
</script>

<style scoped>
.Whitetitle {
    color: #fff;
    font-family: "korolev", sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 40px;
    text-align: center;
}
.WhiteStandard-text {
    font-size: 24px;
    color: #fff;
    text-align: center;
    font-family: "noto-sans", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.EnquireyBanner {
    background-color: #d80e1f;
    padding: 50px;
    padding-top: 70px;
    padding-bottom: 70px;
    background-image: url(https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Screenshot-2024-02-20-at-14.20.56.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
@media only screen and (max-width: 768px) {
  .EnquireyBanner {
    padding: 50px;
  } 
  .Whitetitle {
    color: #fff;
    font-family: "korolev", sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 36px;
    text-align: center;
  }
  .WhiteStandard-text {
    font-size: 20px;
    color: #fff;
    text-align: center;
    font-family: "noto-sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .EnquireyBanner {
    background-color: #d80e1f;
    padding: 50px;
    padding-top: 70px;
    padding-bottom: 70px;
    background-image: url(https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Screenshot-2024-02-20-at-14.20.56.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: revert;
  }
}
</style>
